@tailwind base;
@tailwind components;
@tailwind utilities;

/* App.css */
@keyframes spin-animation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  #spinner {
    transition: transform 5s cubic-bezier(0.33, 1, 0.68, 1); /* Smooth spin effect */
  }
  
  button:disabled {
    opacity: 0.6;
    cursor: not-allowed;
  }
  

  @keyframes glow {
    0% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
    }
    50% {
      box-shadow: 0 0 20px rgba(255, 255, 255, 0.8);
    }
    100% {
      box-shadow: 0 0 10px rgba(255, 255, 255, 0.4);
    }
  }
  .animate-glow {
    animation: glow 1.5s infinite;
  }
  